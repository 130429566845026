import { CompanyService } from 'src/app/services/company.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { IBreadCrumb, Module } from 'src/app/model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LoginService } from 'src/app/services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  public breadcrumbs: IBreadCrumb[];
  private modules: Module[] = [];
  constructor(
    private readonly loginService: LoginService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly companyService: CompanyService,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.getModules();
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
        this.cdr.markForCheck();
      });
  }

  buildBreadCrumb(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: IBreadCrumb[] = []
  ): IBreadCrumb[] {
    //If no routeConfig is avalailable we are on the root path
    let label = route.routeConfig?.data
      ? route.routeConfig.data.breadcrumb
      : '';
    let path = route.routeConfig?.data ? route.routeConfig.path : '';

    // If the route is dynamic route such as ':id', remove it
    const lastRoutePart = path.split('/').pop();
    const isDynamicRoute = lastRoutePart.startsWith(':');
    if (isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart.split(':')[1];
      path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
      label = route.snapshot.params[paramName];
    }

    //In the routeConfig the complete path is not available,
    //so we rebuild it each time
    const nextUrl = path ? `${url}/${path}` : url;

    let moduleIndex = this.modules.findIndex((x) => x.path == label);
    if (moduleIndex >= 0) {
      if (this.loginService.getLoginUser().lang == 'en') {
        label = this.modules[moduleIndex].name;
      } else {
        label = this.modules[moduleIndex].name_gr;
      }
    }

    if (label == 'new') {
      label = this.translateService.instant('Generic.add');
    } else if (label == 'copy') {
      label = this.translateService.instant('Generic.copy');
    } else if (label == 'Search') {
      label = this.translateService.instant('Generic.search');
    } else if (label == 'financialForecast') {
      label = this.translateService.instant('Forecast.financialForecast');
    }

    const breadcrumb: IBreadCrumb = {
      label: label,
      url: nextUrl,
    };
    // Only adding route with non-empty label
    const newBreadcrumbs = breadcrumb.label
      ? [...breadcrumbs, breadcrumb]
      : [...breadcrumbs];
    if (route.firstChild) {
      //If we are not on our current path yet,
      //there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  private getModules() {
    this.companyService.teamworkModules
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((resModule) => {
        this.modules = resModule;
        this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
      });
  }
}
