import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  public promptEvent: any;

  constructor(
    private readonly swUpdate: SwUpdate,
    private readonly translateService: TranslateService
  ) {
    this.swUpdate.versionUpdates
      .pipe(
        filter(
          (event): event is VersionReadyEvent => event.type === 'VERSION_READY'
        )
      )
      .subscribe((event) => {
        if (this.askUserToUpdate()) {
          window.location.reload();
        }
      });
    window.addEventListener('beforeinstallprompt', (event) => {
      this.promptEvent = event;
    });
  }

  private askUserToUpdate(): boolean {
    return confirm(this.translateService.instant('welcome.updatePwa'));
  }

  public installPwa(): void {
    this.promptEvent.prompt();
  }
}
