import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DataItemDiagComponent } from 'src/app/common/entity/data-item-diag/data-item-diag.component';
import { EntityItemFilter } from 'src/app/common/filters';
import { Entity, SearchEntResults } from 'src/app/model';
import { EntityService } from 'src/app/services/entity.service';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.css'],
})
export class GlobalSearchComponent implements OnInit {
  public searchCounters: SearchEntResults[] = [];
  public searchString: string;
  public entityFilters: EntityItemFilter;
  public selectedEnity: Entity;

  constructor(
    private entityService: EntityService,
    private breakpointObserver: BreakpointObserver,
    public route: ActivatedRoute,
    public dialog: MatDialog
  ) {
    this.route.paramMap.subscribe((params) => {
      this.ngOnInit();
    });
  }

  ngOnInit(): void {
    this.searchString = this.route.snapshot.paramMap.get('str');

    this.entityFilters = new EntityItemFilter();
    this.entityFilters.strValue = this.searchString;

    this.getSearchCountersRes();
  }

  getSearchCountersRes() {
    const entityFilters: EntityItemFilter = new EntityItemFilter();
    entityFilters.strValue = this.searchString;
    this.entityService
      .globalSearch(entityFilters)
      .pipe(take(1))
      .subscribe((res) => {
        this.searchCounters = res;
      });
  }

  public showEntityResults(entity: Entity) {
    this.selectedEnity = entity;
  }

  public previewItem(item: any, entityCode: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      itemId: item.id,
      entityCode: entityCode,
    };
    const dialogRef = this.dialog.open(DataItemDiagComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result: any) => {});
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));
}
