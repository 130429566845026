<div *ngIf="isTokenValid != null && isTokenValid && !isSuccessFullyRegister">
  <mat-card class="registerCard">
    <mat-card-content>
      <mat-list role="list">
        <mat-list-item role="listitem">
          <mat-form-field class="input-width">
            <mat-label>{{ 'Generic.language' | translate }}</mat-label>
            <mat-select
              [(ngModel)]="user.lang"
              (ngModelChange)="changeLang($event)"
              [formControl]="langFormControl"
            >
              <mat-option value="en"> English </mat-option>
              <mat-option value="gr"> Ελληνικά </mat-option>
            </mat-select>
            <mat-icon matSuffix>language</mat-icon>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item role="listitem">
          <mat-form-field class="input-width">
            <mat-label>Email</mat-label>
            <input matInput [(ngModel)]="user.email" [disabled]="true" />
          </mat-form-field>
        </mat-list-item>
        <mat-list-item role="listitem">
          <mat-form-field class="input-width">
            <mat-label>{{ 'Register.password' | translate }}</mat-label>
            <input
              matInput
              [type]="hidePass ? 'password' : 'text'"
              [(ngModel)]="user.password"
              [formControl]="passwordFormControl"
            />
            <button
              mat-icon-button
              matSuffix
              (click)="hidePass = !hidePass"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hidePass"
            >
              <mat-icon>{{
                hidePass ? 'visibility_off' : 'visibility'
              }}</mat-icon>
            </button>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item role="listitem">
          <mat-form-field class="input-width">
            <mat-label>{{ 'Register.cpassword' | translate }}</mat-label>
            <input
              matInput
              [type]="hideCPass ? 'password' : 'text'"
              [(ngModel)]="user.cpassword"
              [formControl]="cpasswordFormControl"
            />
            <button
              mat-icon-button
              matSuffix
              (click)="hideCPass = !hideCPass"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hideCPass"
            >
              <mat-icon>{{
                hideCPass ? 'visibility_off' : 'visibility'
              }}</mat-icon>
            </button>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item role="listitem">
          <mat-form-field class="input-width">
            <mat-label>{{ 'Register.firstname' | translate }}</mat-label>
            <input
              matInput
              [(ngModel)]="user.firstname"
              [formControl]="firstnameFormControl"
            />
            <mat-icon matSuffix>mode_edit</mat-icon>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item role="listitem">
          <mat-form-field class="input-width">
            <mat-label>{{ 'Register.lastname' | translate }}</mat-label>
            <input
              matInput
              [(ngModel)]="user.lastname"
              [formControl]="lastnameFormControl"
            />
            <mat-icon matSuffix>mode_edit</mat-icon>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item role="listitem">
          <mat-form-field class="input-width">
            <mat-label>{{ 'Register.nickname' | translate }}</mat-label>
            <span matPrefix>&#64; </span>
            <input
              type="tel"
              matInput
              [(ngModel)]="user.nickname"
              [formControl]="nicknameFormControl"
            />
            <mat-icon matSuffix>mode_edit</mat-icon>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item role="listitem">
          <form class="example-form">
            <mat-form-field class="input-width">
              <mat-label>{{ 'Register.secondEmail' | translate }} </mat-label>
              <input
                matInput
                [formControl]="email2FormControl"
                [(ngModel)]="user.email2"
                placeholder="Ex. pat@example.com"
              />
              <mat-icon matSuffix>alternate_email</mat-icon>
              <mat-error
                *ngIf="
                  email2FormControl.hasError('email') &&
                  !email2FormControl.hasError('required')
                "
              >
                {{ 'Messages.validEmail' | translate }}
              </mat-error>
            </mat-form-field>
          </form>
        </mat-list-item>
        <mat-list-item role="listitem">
          <mat-form-field class="input-width">
            <mat-label>{{ 'Register.birthdate' | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              [(ngModel)]="user.birthdate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item role="listitem">
          <mat-form-field class="input-width">
            <mat-label>{{ 'Register.phone1' | translate }}</mat-label>
            <input matInput type="tel" [(ngModel)]="user.phone1" />
            <mat-icon matSuffix>call</mat-icon>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item role="listitem">
          <mat-form-field class="input-width">
            <mat-label>{{ 'Register.phone2' | translate }}</mat-label>
            <input matInput type="tel" [(ngModel)]="user.phone2" />
            <mat-icon matSuffix>call</mat-icon>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item role="listitem">
          <mat-form-field class="input-width">
            <mat-label>{{ 'Company.department' | translate }}</mat-label>
            <mat-select
              [formControl]="departmentFormControl"
              [(ngModel)]="user.department_id"
            >
              <mat-option
                *ngFor="let department of invitation.company.departments"
                [value]="department.id"
              >
                {{ department.title }} - {{ department.location }}
              </mat-option>
            </mat-select>
            <mat-icon matSuffix>store_mall_directory</mat-icon>
          </mat-form-field>
        </mat-list-item>
      </mat-list>
      <div>
        <div *ngFor="let error of errors">
          <mat-error class="errorArea">
            {{ error }}
          </mat-error>
        </div>
        <mat-error class="errorArea" *ngIf="!passMutch">
          {{ 'Messages.passwordNotMuch' | translate }}
        </mat-error>
        <mat-error
          class="errorArea"
          *ngIf="
            this.passwordFormControl.hasError('pattern') ||
            this.passwordFormControl.hasError('minlength')
          "
          [innerHTML]="'Messages.passwordComplexityError' | translate"
        >
        </mat-error>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button (click)="check()">
        {{ 'Register.register' | translate }}
      </button>
    </mat-card-actions>
  </mat-card>
</div>

<div *ngIf="isTokenValid != null && !isTokenValid">
  <mat-card class="notValidCard">
    {{ 'Messages.notAvaliebleInnvitation' | translate }}
  </mat-card>
</div>

<div *ngIf="isSuccessFullyRegister">
  <mat-card class="successFulRegisterCard">
    {{ 'Messages.successfulRegister' | translate }}
    <button mat-button routerLink="/login">
      {{ 'Generic.login' | translate }}
    </button>
  </mat-card>
</div>
